import React, { useEffect, useState } from 'react'
import LandingForm from '../../../src/containers/LandingForm'
import { setCookie } from '../../../src/utils/cookies'
import { graphql } from 'gatsby'
import queryString from 'query-string-es5'
function AddCustomCss(css) {
    const head = document.getElementsByTagName('head')[0]
    const styleEl = document.createElement('style')
    styleEl.innerHTML = css
    head.appendChild(styleEl)
}

const LandingFormPage = () => {
    const [params, setParams] = useState({
        loaded: false, // used to make sure the component does not display until data is received from the parent
    })

    useEffect(() => {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data.formInit) {
                    const data = event.data
                    AddCustomCss(data.css)

                    const referrer = data.referrer
                    if (data.referrer !== '') {
                        setCookie('referrer', data.referrer)
                    }
                    const { pub, keyword, device } = queryString.parse(
                        data.locationSearch
                    )
                    if (pub) {
                        setCookie('campaignID', pub)
                    }
                    if (device) {
                        setCookie('device', device)
                    }
                    if (keyword) {
                        setCookie('keyword', keyword)
                    }
                    setParams({
                        loaded: true,
                        ...data,
                        pub,
                        device,
                        keyword,
                        referrer,
                    })
                }
            },
            false
        )
        window.parent.postMessage({ eventCode: 'ready' }, '*')
    }, [])

    const customCallback = (str) => {
        window.parent.postMessage(str, '*')
    }

    return params.loaded ? (
        <LandingForm
            isIframe={true}
            {...params}
            customSubmitCallback={customCallback}
        />
    ) : null
}

export default LandingFormPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
